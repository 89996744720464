// src/pages/my-html-page.js

import React from 'react';

const VideoPage = () => {
  const videoDiv = {
    position: 'relative',
    paddingTop: '56.25%'
  };
  const frameEl = {
    border: 'none',
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%'
  }
  return (
    <div style={videoDiv}><iframe src="https://customer-zktghrvay4ttfoiw.cloudflarestream.com/26f23a783441a3ba56fb6a9b3b572cce/iframe?preload=true&autoplay=true&poster=https%3A%2F%2Fcustomer-zktghrvay4ttfoiw.cloudflarestream.com%2F26f23a783441a3ba56fb6a9b3b572cce%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D3s%26height%3D600" style={frameEl} allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
  );
};

export default VideoPage;